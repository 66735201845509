@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro:ital,wght@0,200;0,300;0,500;0,700;0,900;1,200;1,300;1,500;1,700&display=swap');

body {
  min-height: 100vh;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-direction: column;
  background-color: #f1dfd1;
  background: linear-gradient(45deg, #eeeeee, #FAF8F9, #FFFFFF);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: rgb(237 247 243 / 62%);
  color: rgb(19, 35, 34);
  font-family: 'Source Code Pro', monospace;
}