main {
  margin-top: 45px;
}

#dashboard {
  margin-top: 0;
  padding-top: 45px;
  flex: 1;
  background-size: cover;
}

#dashboard > .container img {
  width: 100%;
  max-width: 800px;
  border-top-right-radius: 55px;
  margin-bottom: 15px;
}

#dashboard .card {
  margin-top: 56px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

main > .card {
  max-width: 450px;
}

.card h5 {
  margin-top: 10px;
}

main:not(#dashboard) > .card .card-header {
  font-size: xx-large;
}

.card input {
  margin-bottom: 20px;
}

.card button {
  margin-top: 5px;
  margin-bottom: 5px;
}

a {
  color: rgb(61, 220, 145);
}

.gradient-divider {
  border: 0;
  height: 10px;
  border-radius: 15px;
  background: rgb(0, 0, 0);
  background: linear-gradient(90deg, rgba(2, 19, 1, 0.8) 0%, rgba(61,220,145,1) 35%, rgba(0,255,233,1) 100%);
}

.gradient-footer {
  border: 0;
  height: 10px;
  background: rgb(0, 0, 0);
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.8) 0%, rgba(61,220,145,1) 35%, rgba(0,255,233,1) 100%);
}

.btn-dark {
  color: rgba(0, 0, 0, 0.87);
  background-color: #3ddc91;
  border-color: rgb(61, 220, 145);
}

.btn-dark:hover {
  color: rgba(0, 0, 0, 0.87);
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.87);
}

.alert {
  background-color: hsla(0, 0%, 94%, 0.4);
  margin: 15px 0;
  padding: 10px 15px;
}

.error {
  color: #dc3545;
}

nav, nav.bg-body-tertiary {
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 0px 1px;
  /*background-image: linear-gradient(135deg, #fdfcfb 0%, #f8f5f394 100%);*/
  background-color: rgb(237 247 243 / 62%) !important;
}

.navbar-nav {
  flex-grow: 1;
}

.navbar-brand img {
  height: 40px;
}

#admin-bar {
  float: right;
  margin-top: -10px;
}

#status-bar {
  float: right;
  padding: 0.5rem;
  padding-right: 0;
  opacity: 0.7;
}

#status-bar small {
  font-size: 0.82em;
}

.flex-end {
  justify-content: flex-end;
}

.flex-right {
  margin-left: auto;
}

main.form > .card {
  margin: 15px auto;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.form-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.form-footer span {
  margin-top: 10px;
  font-weight: 500;
  font-size: 1.3em;
}